<template>
  <div class="card-body mt-5">
    <h5>
      {{ roomType }} {{ $t('product-page.room') }} <span>{{ index + 1 }}</span>
    </h5>

    <div class="d-flex justify-content-between">
      <div
        class="p-2 d-flex"
        :style="lang === 'he' ? 'direction:rtl;' : 'direction:ltr;'"
      >
        <span v-if="room.adult === 1"
          >{{ room.adult }} {{ $t('product-page.adult') }}</span
        >
        <span v-else>{{ room.adult }} {{ $t('product-page.adults') }}</span>
        <span v-if="room.child && room.child === 1"
          >, {{ room.child }} {{ $t('product-page.child') }}</span
        >
        <span v-if="room.child && room.child > 1"
          >, {{ room.child }} {{ $t('product-page.children') }}</span
        >

        <!-- <p v-if="!room.infant">{{ $t("product-page.no-babies") }}</p> -->
        <p v-if="room.infant && room.infant === 1">
          , {{ room.infant }} {{ $t('product-page.infant') }}
        </p>
        <p v-if="room.infant && room.infant > 1">
          , {{ room.infant }} {{ $t('product-page.infants') }}
        </p>
      </div>

      <div class="p-2">
        <!-- <p class="m-0">${{ room.totalPrice }}</p> -->
        <span>{{ symbolCurrency }}{{ room.totalPrice }}</span>
        <p>{{ codeCurrency }}</p>
      </div>
    </div>

    <button class="js-del-row" v-if="index > 0" @click="remove">
      <i class="far fa-window-close"></i>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getSymbolFromCurrency from 'currency-symbol-map';

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    roomType() {
      return this.room.roomType ? this.room.roomType.text : '';
    },
    symbolCurrency() {
      return getSymbolFromCurrency(this.room.currency);
    },
    codeCurrency() {
      return `(${this.room.currency})`;
    },
  },
  methods: {
    remove() {
      this.$emit('closed', this.index);
    },
  },
};
</script>
<style scoped>
s {
  opacity: 0.5;
}
</style>
